/* ClientHomepage.css */

/* Banner Styles */
.banner {
    background: #00C8E6;
    color: white;
    padding: 4rem 2rem;
  }
  
  .banner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 3rem;
  }
  
  .banner-title {
    font-size: 1.5rem;
  }
  
  .banner-button {
    border: none;
    padding: 0.5rem 2.5rem;
    border-radius: 10px;
    background: white;
    color: #00C8E6;
  }
  
  .banner-main {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff
  }
  

  
  .banner-main h1 {
    font-size: 5rem;
    font-weight: bold;
    color: #fff

  }
  
  .banner-main h2 {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;

  }
  
  .banner-search {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .banner-search input {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .banner-search button {
    margin-left: 0.5rem;
    border: none;
    border-radius: 50%;
    background: #fff;
    height: 50px;
    width: 50px;
  }
  
  .banner-img {
    width: 100%;
    max-width: 500px;
  }
  
  /* Content Area Styles */
  .content-area {
    padding: 2rem 3rem;
  }
  
  .content-area .content {
    display: flex;
    justify-content: space-between;
  }
  


.word{
    display: flex;
    align-items: center;
  }
  

  .text-content-header h3 {
    font-size: 2rem;
    color: #00C8E6;
  }

  .text-content{
    margin-bottom: 1.5rem;
  }
  
  .text-content-icon svg {
    width: 3.2rem;
    height: 3.2rem;
  }
  
  .text-content h3 {
    font-size: 1.5rem;
  }
  
  .text-content p {
    margin: 0;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center;
  }
  
  /* Footer Styles */
  .footer {
    padding: 1rem;
    background: #00C8E6;
    text-align: center;
    font-size: 1.2rem;
    color: #fff;
  }

  .search-container{
    position: absolute;
    padding: 1rem;
    background-color: #fff;
    color: #00C8E6;
    border-radius: 10px;
    top: 55px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 0px 4px #6ba9b3;
  }


  @media (max-width: 768px) {

    .banner {
        background: #00C8E6;
        color: white;
        padding: 4rem 2rem;
      }

    .banner-main h1{
        font-size: 2.5rem;
    }

    .banner-main h2{
        font-size: 1rem;
    }
    .banner-main {
      flex-direction: column;
      text-align: left;
    }

    .banner-img{
        display: none;
    }

    .content{
        flex-direction: column;
    }
}
  


