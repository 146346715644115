/* ImageCarousel.css */

/* Target the element with class 'my-scroll' and its scrollbar */
.my-scroll::-webkit-scrollbar {
    width: 0px; /* Set scrollbar width */
    height: 0px;
  }
  
  /* Style the track (the background where the thumb slides) */
  .my-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set track color */
  }
  
  /* Style the thumb (the draggable scroll handle) */
  .my-scroll::-webkit-scrollbar-thumb {
    background: #888; /* Set thumb color */
    border-radius: 6px; /* Add rounded corners */
  }
  
  /* Style the thumb when hovering over the scrollbar */
  .my-scroll::-webkit-scrollbar-thumb:hover {
    background: #666; /* Change thumb color on hover */
  }

  
.carousel {
    position: relative;
    margin: auto;
    

  }
  
  .carousel-inner {
    position: relative;
    
  }
  
  .carousel-images {
    display: flex;
    transition: transform 0.5s ease-in-out;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: flex-end;
  }
  
  .carousel-image {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    margin: 10px;
    transition: transform 0.5s ease-in-out, all 0.5s ease-in-out;
  }
  


  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 10px;
  }
  
  .carousel-dots {
    text-align: center;
    margin-top: 10px;
  }
  
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #00C8E6;
  }
  

  @media (max-width: 768px) {

    .carousel{
        font-size: 2.5rem;
        max-width: 100%;
    }

    .carousel-image.active{
        width: 200px;
        height: 200px;
    
      }
}