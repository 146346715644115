.btn-close-custom {
    background-color: white;
    border-radius: 50%;
    color: blue;
    font-size: 1.2rem;
    padding: 0.3rem 0.6rem;
    border: none;
    outline: none;
    cursor: pointer;
  }


  .btn-play-custom {
    background-color: #6b6565;
    width: 100%;
    border-radius: 0px;
    position: absolute;
    bottom:-15px;
    color: #fff;

  }
  
  .btn-close-custom:hover {
    background-color: #f8f9fa;
  }



  .btn-play-custom:hover {
    background-color: #f8f9fa;
  }
  