html, body, #root, #body {
    height: 100%;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings:   "wdth" 100;
}

.roll-text{
    display: inline-block;
}

.open-sans-normal{

    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    font-variation-settings:   "wdth" 100;

}

.my-scroll::-webkit-scrollbar {
    width: 5px; /* Set scrollbar width */
    height: 3px;
  }

  .my-scroll-hidden::-webkit-scrollbar {
    width: 0px; /* Set scrollbar width */
    height: 0px;
  }
  
  /* Style the track (the background where the thumb slides) */
  .my-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set track color */
  }
  
  /* Style the thumb (the draggable scroll handle) */
  .my-scroll::-webkit-scrollbar-thumb {
    background: #888; /* Set thumb color */
    border-radius: 6px; /* Add rounded corners */
  }
  
  /* Style the thumb when hovering over the scrollbar */
  .my-scroll::-webkit-scrollbar-thumb:hover {
    background: #666; /* Change thumb color on hover */
  }
  

.deleted{
  width: 0px;
  height: 0px;
  transition: all 5s ease;
}

